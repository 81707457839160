import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, ActivatedRoute, Router } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { AuthenticationResult } from '@azure/msal-browser';

interface CustomIdTokenClaims {
  roles?: string[];
  name?: string;
  oid?: string;
  tid?: string;
  [key: string]: any; // Allow additional claims
}

interface CustomAccountInfo extends AccountInfo {
  idTokenClaims?: CustomIdTokenClaims;
}

interface Shipment {
  ShipmentId: string;
  QrCodeImageData?: string | undefined;
  SalesPersonId?: string | undefined;
  // Add other fields as necessary
}

@Component({
  selector: 'app-qr',
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule,
    QRCodeModule,
    CommonModule,
    MatProgressSpinnerModule
  ],
  template: `
  <div *ngIf="isLoading" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="noShipments">
    <h2>Finns inga lediga returer,</h2>
    <h2>försök igen senare</h2>
    <button mat-raised-button color="orange" [routerLink]="['/couriers']">STÄNG</button>
  </div>

  <div *ngIf="!isLoading && !noShipments" class="content">
    <div *ngIf="!sendinfo">
      <h2> Visa denna QR-kod hos paketombudet</h2>
    </div>
    <div *ngIf="sendinfo">
      <h2> Skicka följande bild</h2>
      <h2>och länk till kund</h2>
    </div>
    <div class="rectangle">
      <h2><span>{{ courierName }}</span><span *ngIf="weight && weight.length > 0"> - {{ weight }} kg</span></h2>
      <qrcode *ngIf="!shipment?.QrCodeImageData" [qrdata]=shipment!.ShipmentId [width]="200" [errorCorrectionLevel]="'M'"></qrcode>
      <img *ngIf="shipment?.QrCodeImageData" [src]="shipment?.QrCodeImageData">
      {{ shipmentId }}
    </div>

    <div *ngIf="sendinfo" class="sendlink">
      Spårningslänk:
      <a *ngIf="courier === 'postnord'" href="https://tracking.postnord.com/en/?id={{shipmentId}}" target="_blank">https://tracking.postnord.com/en/?id={{shipmentId}}</a>
      <a *ngIf="courier === 'dhl'" href="https://www.dhl.com/se-en/home/tracking/tracking-freight.html?submit=1&tracking-id={{shipmentId}}" target="_blank">{{shipmentId}}</a>
    </div>

    <div *ngIf="!sendinfo" class="buttons">
      <br />
      <button *ngIf="showSendInfo" mat-raised-button color="orange" [routerLink]="['/sendinfo', courier]" [queryParams]="{ weight: weight, shipmentid: shipmentId }">SKICKA VIDARE</button>
      <button mat-raised-button color="orange" (click)="handleButtonClick('USED')">INLÄMNAD</button>
      <button mat-raised-button color="orange" (click)="handleButtonClick('ABORTED')">AVBRYT</button>
    </div>
    <div *ngIf="sendinfo" class="buttons">
      <button mat-raised-button color="orange" [routerLink]="['/couriers']">STÄNG</button>
    </div>

  </div>
  `,
  styleUrls: ['./qr.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class QrComponent implements OnInit{
  courier: string | null = null;
  weight: string | null = null;
  courierName: string | null = null;
  // shipment: ShipmentType | undefined;
  shipmentId: string = '';
  shipment: Shipment | undefined;
  isLoading: boolean = true;
  noShipments: boolean = false;
  sendinfo: boolean = false;
  showSendInfo: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private authService: MsalService
  ) { }

  ngOnInit(): void {
    this.getUserRoles();
    this.route.paramMap.subscribe(params => {
      this.courier = params.get('courier');
      if (this.courier == 'postnord') {
        this.courierName = 'Postnord';
      } else if (this.courier == 'dhl') {
        this.courierName = 'DHL';
      } else {
        this.router.navigate(['/couriers']);
      }
    });

    this.route.queryParamMap.subscribe(queryParams => {
      this.weight = queryParams.get('weight');
      this.sendinfo = queryParams.get('sendinfo') === '1';
      this.shipmentId = queryParams.get('shipmentid') || '';

      if (this.shipmentId === '' || !this.sendinfo ) {
        const shipmentObserver = {
          next: (shipment: Shipment) => {
            this.shipment = shipment;
            this.shipmentId = this.shipment.ShipmentId;
            console.log(this.shipment);
            console.log(this.shipment.ShipmentId);
          },
          error: (err: any) => {
            this.noShipments = true;
            this.isLoading = false;
            console.error('Error:', err);
          },
          complete: () => {
            this.isLoading = false;
            console.log('Request completed');
          }
        };

        this.http.post<Shipment>(environment.backendConfig.apiUrl + `/shipments/${this.courier}`, {"weight": this.weight})
          .subscribe(shipmentObserver);
      } else {
        this.shipment = {
          ShipmentId: this.shipmentId || ''
        };
        this.isLoading = false;
      }
    });
  }

  handleButtonClick(status: string): void {
    console.log('Button clicked');
    const shipmentObserver = {
      next: () => {
        console.log(`Successfully updated shipment ${this.shipmentId} to status ${status}`);
        if (status == "USED") {
          this.snackBar.open("Försändelse inlämnad!", '', {
            duration: 2000,
            panelClass: 'success-snackbar'
          });
        } else {
          this.snackBar.open("Inlämning abruten!", '', {
            duration: 2000,
            panelClass: 'error-snackbar'
          });
        }

        setTimeout(() => {
          this.router.navigate(['/couriers']);
        }, 2000);
      },
      error: (err: any) => {
        console.error(`Error setting ${this.shipmentId} to status ${status}:`, err);
        this.snackBar.open("Fel vid inlämning!", '', {
          duration: 2000,
          panelClass: 'error-snackbar'
        });
        setTimeout(() => {
          this.router.navigate(['/couriers']);
        }, 2000);
      }
    };

    this.http.patch(environment.backendConfig.apiUrl + `/shipments/${this.courier}/` + this.shipmentId, {"status": status})
      .subscribe(shipmentObserver);
  }

  getUserRoles() {
    var account = this.authService.instance.getActiveAccount();
    if (account) {
      this.authService.acquireTokenSilent({
        scopes: ['User.Read'],
        account: account
      }).subscribe({
        next: (response: AuthenticationResult) => {
          account = this.authService.instance.getActiveAccount() as CustomAccountInfo;
          const userRoles = account.idTokenClaims?.roles;
          console.log('ID Token Roles:', userRoles);
          if (userRoles?.includes('SendInfo')) {
            console.log('User has role SendInfo');
            this.showSendInfo = true;

          }
        },
        error: (error) => {
          console.error('Silent token acquisition failed, redirecting to login:', error);
          this.authService.loginRedirect();
        }
      });
    } else {
      console.log('No active account, redirecting to login...');
      this.authService.loginRedirect();
    }
  }
}

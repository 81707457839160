import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { environment } from 'src/environments/environment';
import { AuthenticationResult } from '@azure/msal-browser';

interface CustomIdTokenClaims {
  roles?: string[];
  name?: string;
  oid?: string;
  tid?: string;
  [key: string]: any; // Allow additional claims
}

interface CustomAccountInfo extends AccountInfo {
  idTokenClaims?: CustomIdTokenClaims;
}


@Component({
  selector: 'app-couriers',
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule,
    CommonModule
  ],
  template: `
  <div *ngIf="!userAccess">
    <h2>Åtkomst nekad</h2>
    Du har inte behörighet att använda<br />
    denna applikation
  </div>
  <div *ngIf="userAccess">
    <h2>Välj ombud för att boka retur</h2>
    <div class="rectangle">
      <button mat-raised-button color="orange" [routerLink]="['/weight/postnord']">Postnord</button>
      <button *ngIf="userDHL" mat-raised-button color="orange" [routerLink]="['/weight/dhl']">DHL</button>
      <button *ngIf="!userDHL" mat-raised-button color="gray" [disabled]=true [routerLink]="['/weight/dhl']">DHL</button>
    </div>
    <button mat-raised-button color="orange" [routerLink]="['/tracking']">Tracking</button>
    <button *ngIf="userFullTracker" mat-raised-button color="orange" [routerLink]="['/fulltracking']">Historik</button>
    <div *ngIf="!prodEnv"><h2>Dev</h2></div>
  </div>
`,
  styleUrls: ['./couriers.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CouriersComponent implements OnInit {
  userFullTracker: boolean = false;
  userDHL: boolean = false;
  userAccess: boolean = false;
  prodEnv: boolean = false;

  constructor(
    private authService: MsalService)
  { }

  ngOnInit() {
    this.prodEnv = environment.production;
    this.getUserRoles();
  }

  getUserRoles() {
    var account = this.authService.instance.getActiveAccount();
    if (account) {
      this.authService.acquireTokenSilent({
        scopes: ['User.Read'],
        account: account
      }).subscribe({
        next: (response: AuthenticationResult) => {
          account = this.authService.instance.getActiveAccount() as CustomAccountInfo;
          const userRoles = account.idTokenClaims?.roles;
          console.log('ID Token Roles:', userRoles);
          if (userRoles) {
            this.userAccess = true;
          }
          if (userRoles?.includes('FullTrackers')) {
            console.log('User has role FullTrackers');
            this.userFullTracker = true;
          }
          if (userRoles?.includes('DHL')) {
            console.log('User has role DHL');
            this.userDHL = true;
          }
        },
        error: (error) => {
          console.error('Silent token acquisition failed, redirecting to login:', error);
          this.authService.loginRedirect();
        }
      });
    } else {
      console.log('No active account, redirecting to login...');
      this.authService.loginRedirect();
    }
  }
}

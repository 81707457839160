import { Routes } from '@angular/router';
import { FailedComponent } from './failed/failed.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { MsalGuard } from '@azure/msal-angular';

import { QrComponent } from './qr/qr.component';
import { SendInfoComponent } from './sendinfo/sendinfo.component';
import { CouriersComponent } from './couriers/couriers.component';
import { WeightComponent } from './weight/weight.component';
import { TrackingComponent } from './tracking/tracking.component';
import { FullTrackingComponent } from './fulltracking/fulltracking.component';

export const routes: Routes = [
  { path: 'login-failed', component: FailedComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [MsalGuard] },
  { path: 'couriers', component: CouriersComponent, canActivate: [MsalGuard]  },
  { path: 'weight/:courier', component: WeightComponent, canActivate: [MsalGuard] },
  { path: 'qr/:courier', component: QrComponent, canActivate: [MsalGuard] },
  { path: 'sendinfo/:courier', component: SendInfoComponent, canActivate: [MsalGuard] },
  { path: 'tracking', component: TrackingComponent, canActivate: [MsalGuard]  },
  { path: 'fulltracking', component: FullTrackingComponent, canActivate: [MsalGuard]  },
  { path: '', component: HomeComponent }
];

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-common';
import { AuthenticationResult } from '@azure/msal-browser';

interface CustomIdTokenClaims {
  roles?: string[];
  name?: string;
  oid?: string;
  tid?: string;
  [key: string]: any; // Allow additional claims
}

interface CustomAccountInfo extends AccountInfo {
  idTokenClaims?: CustomIdTokenClaims;
}

interface Shipment {
  AssignedTimestamp: string;
  Courier: string;
  ItemId: string;
  Status: string;
  PoNumber: string;
  CreditNumber: string;
}

@Component({
  selector: 'app-tracking',
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatTableModule
  ],
  template: `
    <div *ngIf="isLoading" class="loading-spinner-container">
      <div class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>

    <div *ngIf="!isLoading" class="content">
      <div *ngIf="shipments.length != 0">
        <h2>Mina returer</h2>

        <div class="shipments-table">
          <table mat-table [dataSource]="shipments" class="mat-elevation-z8">
            <!-- Assigned Timestamp Column -->
            <ng-container matColumnDef="AssignedTimestamp">
              <th mat-header-cell *matHeaderCellDef> Inlämnad </th>
              <td mat-cell *matCellDef="let shipment"> {{ formatDate(shipment.AssignedTimestamp) }} </td>
            </ng-container>

            <!-- Courier Column -->
            <ng-container matColumnDef="Courier">
              <th mat-header-cell *matHeaderCellDef> Speditör </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.Courier}} </td>
            </ng-container>

            <!-- Item ID Column -->
            <ng-container matColumnDef="ItemId">
              <th mat-header-cell *matHeaderCellDef> Shipment Item-ID </th>
              <td mat-cell *matCellDef="let shipment">
                <a href="https://tracking.postnord.com/en/?id={{shipment.ItemId}}" target="_blank">{{shipment.ItemId}}</a>
              </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let shipment"> {{ formatStatus(shipment.Status)}} </td>
            </ng-container>

            <!-- PoNumber Column -->
            <ng-container matColumnDef="PoNumber">
              <th mat-header-cell *matHeaderCellDef> PO-nummer </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.PoNumber}} </td>
            </ng-container>

            <!-- CreditNumber Column -->
            <ng-container matColumnDef="CreditNumber">
              <th mat-header-cell *matHeaderCellDef> Kredit-nummer </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.CreditNumber}} </td>
            </ng-container>

            <!-- Header and Row Declarations -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>

      <div *ngIf="shipments.length === 0">
        Du har inte pågående returer.
      </div>

      <button mat-raised-button color="orange" [routerLink]="['/couriers']" class="close-button">STÄNG</button>

    </div>
  `,
  styleUrl: './tracking.component.css',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [DatePipe],
})
export class TrackingComponent implements OnInit {
  shipments: Shipment[] = [];
  displayedColumns: string[] = ['AssignedTimestamp', 'ItemId', 'Status'];
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private datePipe: DatePipe,
    private authService: MsalService
  ) { }

  ngOnInit(): void {
    this.getUserRoles();
    const shipmentObserver = {
      next: (shipments: Shipment[]) => {
        this.shipments = shipments;
        this.isLoading = false;
      },
      error: (err: any) => {
        console.error('No shipments available', err);
        this.isLoading = false;
      }
    };

    this.http.get<Shipment[]>(environment.backendConfig.apiUrl + '/myshipments')
      .subscribe(shipmentObserver);
  }

  formatDate(utcDate: string): string {
    const localDate = new Date(utcDate);
    return this.datePipe.transform(localDate, 'yyyy-MM-dd HH:mm') || '';
  }

  formatStatus (status: string): string {
    if (status == "USED") return "Inlämnad";
    if (status == "RECEIVED") return "På väg";
    if (status == "DELIVERED") return "Levererad";
    return "Okänd";
  }

  getUserRoles() {
    var account = this.authService.instance.getActiveAccount();
    if (account) {
      this.authService.acquireTokenSilent({
        scopes: ['User.Read'],
        account: account
      }).subscribe({
        next: (response: AuthenticationResult) => {
          account = this.authService.instance.getActiveAccount() as CustomAccountInfo;
          const userRoles = account.idTokenClaims?.roles;
          console.log('ID Token Roles:', userRoles);
          if (userRoles?.includes('SendInfo')) {
            console.log('User has role SendInfo');
            this.displayedColumns = ['AssignedTimestamp', 'ItemId', 'Status','PoNumber', 'CreditNumber'];
          }
        },
        error: (error) => {
          console.error('Silent token acquisition failed, redirecting to login:', error);
          this.authService.loginRedirect();
        }
      });
    } else {
      console.log('No active account, redirecting to login...');
      this.authService.loginRedirect();
    }
  }
}

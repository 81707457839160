import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

interface Shipment {
  AssignedTimestamp: string;
  Courier: string;
  ItemId: string;
  Status: string;
  SalesPersonId: string;
  SalesPersonName: string;
  CompanyName: string;
  PoNumber: string;
  CreditNumber: string;
}

@Component({
  selector: 'app-fulltracking',
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule
  ],
  template: `
    <div *ngIf="isLoading" class="loading-spinner-container">
      <div class="loading-spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>

    <div *ngIf="!isLoading" class="content">
      <div *ngIf="shipments.length != 0">
        <h2>Pågående returer</h2>

        <!-- Search Box -->
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event)" placeholder="Sök försändelse">
        </mat-form-field>

        <div class="shipments-table">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <!-- Assigned Timestamp Column -->
            <ng-container matColumnDef="AssignedTimestamp">
              <th mat-header-cell *matHeaderCellDef> Inlämnad </th>
              <td mat-cell *matCellDef="let shipment"> {{ formatDate(shipment.AssignedTimestamp) }} </td>
            </ng-container>

            <!-- Courier Column -->
            <ng-container matColumnDef="Courier">
              <th mat-header-cell *matHeaderCellDef> Speditör </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.Courier}} </td>
            </ng-container>

            <!-- Item ID Column -->
            <ng-container matColumnDef="ItemId">
              <th mat-header-cell *matHeaderCellDef> Shipment Item-ID </th>
              <td mat-cell *matCellDef="let shipment">
                <a *ngIf="shipment.Courier === 'postnord'" href="https://tracking.postnord.com/en/?id={{shipment.ItemId}}" target="_blank">{{shipment.ItemId}}</a>

                <a *ngIf="shipment.Courier === 'dhl'" href="https://www.dhl.com/se-en/home/tracking/tracking-freight.html?submit=1&tracking-id={{shipment.ItemId}}" target="_blank">{{shipment.ItemId}}</a>
              </td>
            </ng-container>

            <!-- SalesPersonId Column -->
            <ng-container matColumnDef="SalesPersonId">
              <th mat-header-cell *matHeaderCellDef> SäljarId </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.SalesPersonId}} </td>
            </ng-container>

            <!-- SalesPersonName Column -->
            <ng-container matColumnDef="SalesPersonName">
              <th mat-header-cell *matHeaderCellDef> Säljare </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.SalesPersonName}} </td>
            </ng-container>

            <!-- CompanyName Column -->
            <ng-container matColumnDef="CompanyName">
              <th mat-header-cell *matHeaderCellDef> Företag </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.CompanyName}} </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef> Status </th>
              <td mat-cell *matCellDef="let shipment"> {{ formatStatus(shipment.Status)}} </td>
            </ng-container>

            <!-- PoNumber Column -->
            <ng-container matColumnDef="PoNumber">
              <th mat-header-cell *matHeaderCellDef> PO-nummer </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.PoNumber}} </td>
            </ng-container>

            <!-- CreditNumber Column -->
            <ng-container matColumnDef="CreditNumber">
              <th mat-header-cell *matHeaderCellDef> Kredit-nummer </th>
              <td mat-cell *matCellDef="let shipment"> {{shipment.CreditNumber}} </td>
            </ng-container>

            <!-- Header and Row Declarations -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>

      <div *ngIf="shipments.length === 0">
        Du har inte pågående returer.
      </div>

      <button mat-raised-button color="orange" [routerLink]="['/couriers']" class="close-button">STÄNG</button>

    </div>
  `,
  styleUrl: './fulltracking.component.css',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [DatePipe],
})
export class FullTrackingComponent implements OnInit {
  shipments: Shipment[] = [];
  displayedColumns: string[] = ['AssignedTimestamp', 'Courier', 'ItemId', 'SalesPersonId',
                                'SalesPersonName', 'CompanyName', 'PoNumber', 'CreditNumber', 'Status'];
  dataSource: MatTableDataSource<Shipment> = new MatTableDataSource();
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    const shipmentObserver = {
      next: (shipments: Shipment[]) => {
        this.shipments = shipments;
        this.dataSource.data = shipments;
        this.isLoading = false;
      },
      error: (err: any) => {
        console.error('No shipments available', err);
        this.isLoading = false;
      }
    };

    this.http.get<Shipment[]>(environment.backendConfig.apiUrl + '/trackshipments')
      .subscribe(shipmentObserver);
  }

  formatDate(utcDate: string): string {
    const localDate = new Date(utcDate);
    return this.datePipe.transform(localDate, 'yyyy-MM-dd HH:mm') || '';
  }

  formatStatus (status: string): string {
    if (status == "USED") return "Inlämnad";
    if (status == "RECEIVED") return "På väg";
    if (status == "DELIVERED") return "Levererad";
    return "Okänd";
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    let modifiedFilterValue;

    if (filterValue.startsWith(']C1')) {
      modifiedFilterValue = filterValue.slice(3);
    } else {
      modifiedFilterValue = filterValue;
    }
    this.dataSource.filter = modifiedFilterValue.trim().toLowerCase();
    console.log(modifiedFilterValue);
  }
}

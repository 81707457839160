import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, ActivatedRoute, Router } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

interface Shipment {
  ShipmentId: string;
  QrCodeImageData: string | undefined;
  // Add other fields as necessary
}

@Component({
  selector: 'app-sendinfo',
  standalone: true,
  imports: [
    RouterLink,
    MatButtonModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    QRCodeModule,
    CommonModule,
    MatProgressSpinnerModule
  ],
  template: `
  <div *ngIf="isLoading" class="loading-spinner">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="noShipments">
    <h2>Finns inga lediga returer,</h2>
    <h2>försök igen senare</h2>
    <button mat-raised-button color="orange" [routerLink]="['/couriers']">STÄNG</button>
  </div>

  <div *ngIf="!isLoading && !noShipments" class="content">
    <h2> Fyll i information för vidarebefordran</h2>
    <!-- <br /> -->
    <mat-form-field class="example-form-field">
      <mat-label>PO-nummer</mat-label>
      <input #firstField autofocus matInput type="text" [(ngModel)]="poNumber">
    </mat-form-field>
    <mat-form-field class="example-form-field">
      <mat-label>Kredit-nummer</mat-label>
      <input matInput type="text" [(ngModel)]="creditNumber">
    </mat-form-field>

    <br />
    <!-- <button mat-raised-button color="orange" [routerLink]="['/qr', courier]" [queryParams]="{ weight: weight, sendinfo: 1 }">SKICKA VIDARE</button> -->
    <button mat-raised-button color="orange" (click)="handleButtonSendInfoClick('USED')">SKICKA VIDARE</button>
    <button mat-raised-button color="orange" (click)="handleButtonAbortClick('ABORTED')">AVBRYT</button>
  </div>
  `,
  styleUrls: ['./sendinfo.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SendInfoComponent implements OnInit{
  @ViewChild('firstField') firstField!: ElementRef;
  courier: string | null = null;
  weight: string | null = null;
  courierName: string | null = null;
  // shipment: ShipmentType | undefined;
  shipmentId: string = '';
  shipment: Shipment | undefined;
  poNumber: string = '';
  creditNumber: string = '';
  isLoading: boolean = true;
  noShipments: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.courier = params.get('courier');
      if (this.courier == 'postnord') {
        this.courierName = 'Postnord';
      } else if (this.courier == 'dhl') {
        this.courierName = 'DHL';
      } else {
        this.router.navigate(['/couriers']);
      }
      // You can use the `courier` value here as needed
    });

    this.route.queryParamMap.subscribe(queryParams => {
      this.weight = queryParams.get('weight');
      this.shipmentId = queryParams.get('shipmentid') || '';

      if (this.shipmentId === '') {
        const shipmentObserver = {
          next: (shipment: Shipment) => {
            this.shipment = shipment;
            this.shipmentId = this.shipment.ShipmentId;

            console.log(this.shipment);
            console.log(this.shipment.ShipmentId);
          },
          error: (err: any) => {
            this.noShipments = true;
            this.isLoading = false;
            console.error('Error:', err);
          },
          complete: () => {
            this.isLoading = false;
            console.log('Request completed');
          }
        };

        this.http.post<Shipment>(environment.backendConfig.apiUrl + `/shipments/${this.courier}`, {"weight": this.weight})
          .subscribe(shipmentObserver);
      } else {
        this.isLoading = false;
      }
      setTimeout(() => {
        this.firstField.nativeElement.focus();
      }, 0);
    });

  }

  handleButtonAbortClick(status: string): void {
    console.log('Button clicked');
    const shipmentObserver = {
      next: () => {
        console.log(`Successfully updated shipment ${this.shipmentId} to status ${status}`);
        this.snackBar.open("Inlämning abruten!", '', {
          duration: 2000,
          panelClass: 'error-snackbar'
        });

        setTimeout(() => {
          this.router.navigate(['/couriers']);
        }, 2000);
      },
      error: (err: any) => {
        console.error(`Error setting ${this.shipmentId} to status ${status}:`, err);
        this.snackBar.open("Fel vid inlämning!", '', {
          duration: 2000,
          panelClass: 'error-snackbar'
        });
        setTimeout(() => {
          this.router.navigate(['/couriers']);
        }, 2000);
      }
    };

    this.http.patch(environment.backendConfig.apiUrl + `/shipments/${this.courier}/` + this.shipmentId, {"status": status})
      .subscribe(shipmentObserver);
  }

  handleButtonSendInfoClick(status: string): void {
    console.log('Button SendInfo clicked');
    const shipmentObserver = {
      next: () => {
        console.log(`Successfully updated shipment ${this.shipmentId} to status ${status}`);
        this.router.navigate(['/qr', this.courier], {
          queryParams: { weight: this.weight, shipmentid: this.shipmentId, sendinfo: 1 }
        });
      },
      error: (err: any) => {
        console.error(`Error setting ${this.shipmentId} to status ${status}:`, err);
        this.snackBar.open("Fel vid inlämning!", '', {
          duration: 2000,
          panelClass: 'error-snackbar'
        });
        setTimeout(() => {
          this.router.navigate(['/couriers']);
        }, 2000);
      }
    };

    this.http.patch(environment.backendConfig.apiUrl + `/shipments/${this.courier}/` + this.shipmentId, {
      "status": status,
      "ponumber": this.poNumber,
      "creditnumber": this.creditNumber
    }).subscribe(shipmentObserver);
  }
}
